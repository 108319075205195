module.exports={
	"checkin": "Checkin",
	"checkout": "Checkout",
	"rooms": "Quarto||||Quartos",
	"guests": "Convidados",
	"code": "Você tem um código promocional?",
	"promo_code": "Código promocional",
	"button_text": "Verificar disponibilidade",
	"adults": "Adulto||||Adultos",
	"children": "Criança||||Crianças",
	"nights": "Num. noites",
	"children_ages": "Crianças idades (anos)",
	"age": "Età",
	"year": "ano||||anos",
	"loader_text": "Estamos procurando a <span class='genius'>melhor solução...</span>",
	"checkin_error_text": "Escolha a data da sua chegada",
	"checkout_error_text": "Escolha a data da sua partida"
}
