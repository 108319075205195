const $ = global.$G.jquery
const logger = global.$G.logger
const Polyglot = global.$G.Polyglot
const moment = global.$G.moment
const _template = global.$G._template
const validate = require('./ConfigurationValidator')
const GoogleAnalytics = require('./GoogleAnalytics')
const defaultCheckin = require('./helpers').defaultCheckin

let requested_config = {}

class App {
  run() {
    const script = document.getElementById('bookingenius-widget-script')

    try {
      // La configurazione del widget prelevata dal tag script
      requested_config = $G.bgwidget.config

      const { errors, warnings } = validate(requested_config)

      if (errors.length || warnings.length) {
        errors.forEach(error => {
          logger.error(error.attr + ' ' + error.message)
        })

        warnings.forEach(warning => {
          logger.warn(warning.attr + ' ' + warning.message)
        })
      }
    } catch (exception) {
      logger.error('Cannot parse form configuration JSON')
      logger.error(exception)
      logger.warn('Using defaults')
    }

    // Le impostazioni di lingua e data
    const locale = script.getAttribute('data-locale') || 'en'
    const is_live = script.getAttribute('data-live') == 'true' ? true : false
    const polyglot = new Polyglot({ locale })
    const messages = global.$G.bgwidget.messages[locale]
    polyglot.extend(messages)

    // Il canale di vendita
    const channel = script.getAttribute('data-channel')

    /* ======================================================================
		|
		|   	Pubblico la LivePreview
		|
		*/
    if (is_live) {
      global.$G.bgwidget.LivePreview = require('./LivePreview')
    }

    const FormDom = require('./FormDom.js')

    const template = require('../assets/templates/form.tpl')
    const popover = require('../assets/templates/popover.tpl')

    global.$G.bgwidget.templates = {}
    global.$G.bgwidget.templates.form = template
    global.$G.bgwidget.templates.popover = popover

    if (requested_config.font_url) {
      $('<link />', {
        rel: 'stylesheet',
        href: requested_config.font_url
      }).appendTo('head')
    }

    // La skin del selettore ospiti
    moment.locale(locale)
    requested_config.locale = locale

    const { phrases } = polyglot
    let context = Object.assign(phrases, requested_config, polyglot)
    context.polyglot = polyglot

    const popover_html = popover(context)

    context.popover = popover_html
    const checkinDate = defaultCheckin(
      new Date(),
      requested_config.default_checkin
    )

    context.title_bar_position = requested_config.title_bar_position
    context.dismiss_icon_position =
      requested_config.dismiss_icon_mobile_position || 'top-right'

    if (requested_config.booking_embedded) {
      const modal = require('../assets/templates/modal.tpl')
      const modal_html = modal(context)
      $('body').append(modal_html)
    }

    // Imposto le date di arrivo e di partenza
    // il canale, l'orientamento e i limiti di età per i bambini
    context.checkin_date = requested_config.auto_fill_dates
      ? moment(checkinDate).format(requested_config.datepicker_date_format)
      : ''
    context.checkout_date = requested_config.auto_fill_dates
      ? moment(checkinDate)
          .add(requested_config.min_nights, 'DAY')
          .format(requested_config.datepicker_date_format)
      : ''

    context.min_date = checkinDate
    context.channel = channel
    context.locale = locale
    context.is_vertical =
      requested_config.orientation === 'vertical' ? true : false
    context.min_children_age = requested_config.min_children_age || 0
    context.max_children_age = requested_config.max_children_age || 17

    if (
      requested_config.button_text ===
      global.$G.bgwidget.messages['it'].button_text
    ) {
      context.button_text = messages.button_text
    } else {
      context.button_text = requested_config.button_text
    }

    global.$G.bgwidget.context = context

    const html = template(context)

    logger.info('injecting HTML')
    $('#bookingenius-widget').html(html)

    // Se trova GoogleAnalytics
    // Decora il form con il codice
    // per il tracking cross origin
    GoogleAnalytics.ready(ga => {
      ga.decorate('#bgform form')
    })

    global.$G.bgwidget.FormDom = FormDom
    const form_dom = new FormDom(context)
    form_dom.init()

    logger.info('Form has been initialized')
    document
      .getElementsByTagName('body')[0]
      .dispatchEvent(new CustomEvent('bookingenius-widget-ready'))
  }
}

module.exports = App
