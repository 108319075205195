const $ = global.$G.jquery;
let $label;
let $input;

class PromoCode {

	constructor() {

    $label = $( "#bgform label.anchor" );
		$input = $( "#bgform input[name=code]" );

    this.$input = $input;

    $input.on( "keyup",  e => {
    	if ( !this.hasContent() && e.keyCode === 27 ) {
    		this.hide();
    	}
    });


    $( "body" ).on( "click", $label.selector, e => {
      e.stopPropagation();
      this.show();
    });

    $( "body" ).on( "keyup", e => {
        e.stopPropagation();
    		if ( e.keyCode === 27 && !this.hasContent() ) {        
    			this.hide();
    		}
    });

    $( "body" ).on( "click", () => {
      if ( !this.hasContent() ) {
         this.hide();
       }
    });

	}
  hasContent() {
    return this.$input.val().length > 0;
  }
	show() {
		$label.addClass( "hidden" );
    $input.addClass( "visible" ).focus();
	}
	hide() {
		$input.removeClass( "visible" );
        $label.removeClass( "hidden" );
	}
}

module.exports = PromoCode;
