const Skin = require( './Skin.js' );

class ResponsiveSkin extends Skin {
	constructor(  ) {
        super();
    }

    compile() {
    	const css = `

		#bookingenius-widget[max-width~="300px"] .field1,
    	#bookingenius-widget[max-width~="300px"] .field2,
    	#bookingenius-widget[max-width~="300px"] .field3,
    	#bookingenius-widget[max-width~="300px"] .field4, 
    	#bookingenius-widget[max-width~="300px"] .field5,
    	#bookingenius-widget[max-width~="300px"] .field6 {
		  background-color: green;
		  -webkit-box-sizing: border-box;
		          box-sizing: border-box;
		  float: left;
		  padding: 0 8px;
		  width: 100%;
		}

		#bookingenius-widget[min-width~="300px"][max-width~="768px"] .field1,
    	#bookingenius-widget[min-width~="300px"][max-width~="768px"] .field2,
    	#bookingenius-widget[min-width~="300px"][max-width~="768px"] .field3,
    	#bookingenius-widget[min-width~="300px"][max-width~="768px"] .field4, 
    	#bookingenius-widget[min-width~="300px"][max-width~="768px"] .field5,
    	#bookingenius-widget[min-width~="300px"][max-width~="768px"] .field6 {
		  background-color: red;
		  -webkit-box-sizing: border-box;
		          box-sizing: border-box;
		  float: left;
		  padding: 0 8px;
		  width: 100%;
		}

    	#bookingenius-widget[min-width~="768px"][max-width~="5000px"] .field1,
    	#bookingenius-widget[min-width~="768px"][max-width~="5000px"] .field2,
    	#bookingenius-widget[min-width~="768px"][max-width~="5000px"] .field3,
    	#bookingenius-widget[min-width~="768px"][max-width~="5000px"] .field4, 
    	#bookingenius-widget[min-width~="768px"][max-width~="5000px"] .field5,
    	#bookingenius-widget[min-width~="768px"][max-width~="5000px"] .field6 {
		  background-color: blue;
		  -webkit-box-sizing: border-box;
		          box-sizing: border-box;
		  float: left;
		  padding: 0 8px;
		  width: 16.66666666666667%;
		}

		
		
		
		
    	`;
    	this.css = css;
    	return css;
    }
}

module.exports = ResponsiveSkin;