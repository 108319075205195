const errors = [];
const warnings = [];

function check_max_rooms( configuration ) {
	if ( configuration.max_rooms < 1 ) {
		configuration.max_rooms = 1;

		warnings.push({
			attr: "max_rooms",
			message: "Max rooms cannot be less than 1."
		});

	}
}

function check_max_room_guests( configuration ) {
	if ( configuration.default_room_guests > configuration.max_adults ) {
		warnings.push({
			attr: "max_room_guests",
			message: "Max room guests cannot be less than max adults."
		});

		configuration.default_room_guests = configuration.max_adults;
	}
}

function validate( configuration ) {
	check_max_room_guests( configuration );
	check_max_rooms( configuration );
	return { warnings, errors };
}

module.exports = validate