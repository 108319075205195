module.exports={
	"checkin": "Arrivée",
	"checkout": "Départ",
	"rooms": "Chambre||||Chambres",
	"guests": "Clients",
	"code": "Avez-vous un code promotionnel?",
	"promo_code": "Code promotionnel",
	"button_text": "Vérifier la disponibilité",
	"adults": "Adulte||||Adultes",
	"children": "Enfant||||Enfants",
	"nights": "Nuits",
	"children_ages": "Âge des enfants (ans)",
	"age": "Âge",
	"year": "année||||ans",
	"loader_text": "Nous recherchons la <span class='genius'>meilleure solution...</span>",
	"checkin_error_text": "Choisissez la date de votre arrivée",
	"checkout_error_text": "Choisissez la date de votre départ"
}
