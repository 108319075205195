const $ = $G.jquery;
const TitleBar = require( './TitleBar' );
const Draggable = require( './Draggable.js' );

function center( $modal ) {
	const w = $modal.width();
	const h = $modal.height();

	$modal.css( "top", "50%" );
	$modal.css( "left", "50%" );
	$modal.css( "margin", ( -h / 2 ) + "px " + ( -w / 2 ) + "px");
}

class Modal {

	enableDragging( selector ) {
		new Draggable( this.$content.selector );
	}

	constructor( selector, options ) {
		const defaults = {
			draggable: false
		}
		this.selector = selector;
		this.options = Object.assign( defaults, options );
		this.selector = selector;
		this.$modal = $( selector );
		this.$content = this.$modal.find( ".bg-booking-modal-content" );
		this.dismiss = this.$modal.find( ".bg-booking-modal-dismiss" );
		this.dismissMobile = this.$modal.find( ".bg-booking-modal-dismiss-mobile" );

		if ( this.options.draggable ) {
			this.enableDragging( selector );
		}
		
		this.dismissMobile.on( "click", e => {
			this.hide();
		});

		$( window ).on( "resize", () => {
			center( this.$content );
		});
	}
	addTitleBar() {
		this.title_bar = new TitleBar( this );
	}
	show() {
		center( this.$content );
		this.$modal.addClass( "visible" );
		this.$content.addClass( "visible" );
		this.dismiss.addClass( "visible" );
		this.dismissMobile.addClass( "visible" );
	}
	flip() {
		this.$content.addClass( "flip" );
	}
	hide() {
		this.$modal.removeClass( "visible" );
		this.$content.removeClass( "visible" );
		this.dismiss.removeClass( "visible" );
		this.dismissMobile.removeClass( "visible" );
		setTimeout(() => {
			this.$content.removeClass( "flip" );
		}, 1000);
	}
}

module.exports = Modal;
