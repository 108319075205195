function isValidDefaultCheckin(value) {
  const pattern = /^(([0-9]|10)|w\d)$/gi
  return pattern.test(String(value))
}

/**
 *
 * @param {Date} checkin
 * @param {string} configDefaultCheckin
 */
function defaultCheckin(checkin, configDefaultCheckin) {
  const defCheckin = isValidDefaultCheckin(configDefaultCheckin)
    ? configDefaultCheckin
    : '0'
  const daysToAdd = parseInt(defCheckin, 10)

  if (!isNaN(daysToAdd)) {
    // Se è un numero
    const updatedCheckin = new Date(checkin)
    updatedCheckin.setDate(updatedCheckin.getDate() + daysToAdd)
    return updatedCheckin
  } else {
    // Se è w0,w1,w2 ecc...
    const [_, dayOfTheWeek] = configDefaultCheckin
    const updatedCheckin = new Date(checkin)
    updatedCheckin.setDate(
      updatedCheckin.getDate() +
        ((dayOfTheWeek - 1 - updatedCheckin.getDay() + 7) % 7) +
        1
    )
    return updatedCheckin
  }
}

export { defaultCheckin, isValidDefaultCheckin }
