const $ = global.$G.jquery
const webuiPopover = global.$G.webuiPopover
const Reservation = require('../models/Reservation')
const logger = global.$G.logger
const select = global.$G.select
const tabs = global.$G.tabs
const Popover = global.$G.Popover
const moment = global.$G.moment
const title_bar = global.$G.title_bar
const Modal = require('./Modal')
const PromoCode = require('./PromoCode')
const GoogleAnalytics = require('./GoogleAnalytics')
const DateFormatsLookup = require('./DateFormatsLookup')
const defaultCheckin = require('./helpers').defaultCheckin

class FormDom {
  constructor(config) {
    this.channel = config.channel
    const { booking_embedded } = config
    this.booking_embedded = booking_embedded
    this.locale = config.locale
    this.form = $('#bgform form')
    this.checkin_datepicker = this.form.find("input[name='checkin']")
    this.checkout_datepicker = this.form.find("input[name='checkout']")
    this.guests = this.form.find('.bg-room-guests')
    this.code = this.form.find("input[name='code']")
    this.min_nights = config.min_nights
    this.max_nights = config.max_nights
    this.date_format = config.datepicker_date_format

    const checkinDate = defaultCheckin(new Date(), config.default_checkin)

    this.checkin_dp_options = {
      numberOfMonths: 1,
      dateFormat: DateFormatsLookup[this.date_format],
      minDate: checkinDate,
      beforeShow: function(textbox, instance) {
        instance.dpDiv.addClass('bg-booking-calendar')
        instance.dpDiv.removeClass('bg-booking-calendar-mobile')
      },
      onSelect: (date, instance) => {
        this.reservation.checkin = this.datepickerDateToUTC(instance)
        if (this.checkin_datepicker.parent().hasClass('error')) {
          this.checkin_datepicker.parent().removeClass('error')
        }
        this.updateCheckout(this.reservation.checkin)
      }
    }
    this.checkout_dp_options = {
      numberOfMonths: 1,
      minDate: moment(checkinDate)
        .add(config.min_nights, 'days')
        .toDate(),
      maxDate: moment(checkinDate)
        .add(config.max_nights, 'days')
        .toDate(),
      dateFormat: DateFormatsLookup[this.date_format],
      beforeShow: function($textbox, $instance) {
        $instance.dpDiv.addClass('bg-booking-calendar')
        $instance.dpDiv.removeClass('bg-booking-calendar-mobile')
      },
      onSelect: (date, instance) => {
        const checkout_date = this.datepickerDateToUTC(instance)

        this.reservation.checkout = moment(checkout_date).format('YYYY-MM-DD')
        if (this.checkout_datepicker.parent().hasClass('error')) {
          this.checkout_datepicker.parent().removeClass('error')
        }
      }
    }

    this.reservation = new Reservation()

    if (config.compact) {
      this.num_nights_dropdown = $(
        "#form-prebooking div[name='bg-num-nights'] "
      )
      this.reservation.num_nights = this.min_nights
    }
  }
  init() {
    select.init()
    tabs.init()
    const pop1 = new Popover(this.guests)

    this.setUrl()

    // Setta il numero di notti selezionate dall'utente
    if (this.num_nights_dropdown) {
      this.num_nights_dropdown.on('change', e => {
        this.reservation.num_nights = parseInt(
          $(e.target)
            .bgselect()
            .get_value(),
          10
        )
      })
    }

    this.checkin_datepicker.datepicker(this.checkin_dp_options)

    // this.checkin_datepicker.datepicker( this.checkin_dp_options );

    this.code.on('change', e => {
      this.reservation.code = $(e.target).val()
    })

    this.checkout_datepicker.datepicker(this.checkout_dp_options)

    this.guests.on('click', e => {
      this.guests.addClass('active')
    })

    // Il click sulle icone dei calendari mostra il datepicker
    $('body').on('click', '.bg-booking-append.--clickable', e => {
      $(e.target)
        .prev()
        .trigger('focus')
    })

    $('.bg-booking-nav a').on('click', e => {
      e.preventDefault()
    })

    $("#form-prebooking [name=bg-num-rooms], div[name='bg-num-nights']").on(
      'click',
      function() {
        WebuiPopovers.hideAll()
      }
    )

    // Selezione numero camere
    $('#form-prebooking [name=bg-num-rooms]').on('change', (e, value) => {
      var num_rooms = parseInt(value, 10)
      var popover = $('.bg-booking-popover').find('div:first')

      var li = popover.find('.bg-booking-nav li')

      for (var i = 0; i < li.length; i++) {
        if (i <= num_rooms - 1) {
          $(li[i]).removeClass('bg-booking-hidden')
        }
        if (i > num_rooms - 1) {
          $(li[i]).addClass('bg-booking-hidden')
          var $select = $(
            $(li[i])
              .find('a')
              .attr('href')
          ).find('.bg-room-' + (i + 1) + '-children')
          $select.bgselect().reset()
        }
      }
      $("a[href='[data-tab=bg-room-1]']")
        .parent('li')
        .trigger('click')

      this.calcolaOspiti()
    })

    // Visualizza le select per l'inserimento
    // dell'età dei bambini
    $('body').on(
      'change',
      '.bg-room-1-children,.bg-room-2-children,.bg-room-3-children',
      function(e, value) {
        var $this = $(this)
        var pattern = /[0-9]/i

        var num_children = parseInt(
          pattern.exec($this.bgselect().get_value())[0],
          10
        )
        var $children_age = $this.next().find('[data-role=select]')

        $children_age.each(function(i, v) {
          var $item = $(v)
          if (i < num_children) {
            $item.removeClass('bg-booking-hidden')
          } else {
            $item.addClass('bg-booking-hidden')
            $item.bgselect().setValue('Età ')
          }
        })
        if (num_children > 0) {
          $this.next().removeClass('bg-booking-hidden')
        } else {
          $this.next().addClass('bg-booking-hidden')
        }
        pop1.refresh()
      }
    )

    $('body').on(
      'change',
      '.bg-room-1-adults,.bg-room-2-adults,.bg-room-3-adults,.bg-room-1-children,.bg-room-2-children,.bg-room-3-children',
      value => {
        this.calcolaOspiti()
      }
    )

    $('body').on(
      'change',
      "#webuiPopover0 [data-tab='bg-room-1-children-setup'] .bg-booking-select, #webuiPopover0 [data-tab='bg-room-2-children-setup'] .bg-booking-select, #webuiPopover0 [data-tab='bg-room-3-children-setup'] .bg-booking-select",
      e => {
        this.calcolaOspiti()
      }
    )

    $('#bgform button.bg-booking-submit').on('click', e => {
      const checkin_date = this.checkin_datepicker.datepicker('getDate')
      const checkout_date = this.checkout_datepicker.datepicker('getDate')
      if (checkin_date && checkout_date) {
        this.submitForm()
      } else {
        if (!checkin_date) {
          this.checkin_datepicker.parent().addClass('error')
        }

        if (!checkout_date) {
          this.checkout_datepicker.parent().addClass('error')
        }
      }
    })

    // Widget codice promozionale
    let promo_code = new PromoCode()
  }
  datepickerDateToUTC({ selectedDay, selectedMonth, selectedYear }) {
    if (selectedDay.length === 1) {
      selectedDay = '0' + selectedDay
    }
    if (selectedMonth + 1 < 10) {
      selectedMonth = '0' + (selectedMonth + 1)
    } else {
      selectedMonth = selectedMonth + 1
    }
    // formato YYYY-MM-DD
    return `${selectedYear}-${selectedMonth}-${selectedDay}`
  }
  static isFormFixed() {
    var fixedParentTop = Array.from($('#bookingenius-widget').parents()).reduce(
      (acc, ancestor) => {
        const w = window.getComputedStyle(ancestor)
        const p = w.getPropertyValue('position')

        if (!acc && p === 'fixed') {
          return ancestor.getBoundingClientRect().top
        }
        return acc
      },
      false
    )
    return fixedParentTop ? true : false
  }
  updateCheckout(checkin_date) {
    var endDate = moment(checkin_date, 'YYYY-MM-DD')

    endDate.add(this.min_nights, 'days')
    let maxDate = moment(checkin_date, 'YYYY-MM-DD').add(
      this.max_nights,
      'days'
    )

    this.checkout_datepicker.datepicker('setDate', endDate.toDate())
    this.reservation.checkout = endDate.format('YYYY-MM-DD')
    this.checkout_datepicker.datepicker('option', 'minDate', endDate.toDate())
    this.checkout_datepicker.datepicker('option', 'maxDate', maxDate.toDate())
    if (this.checkout_datepicker.parent().hasClass('error')) {
      this.checkout_datepicker.parent().removeClass('error')
    }
  }
  calcolaOspiti() {
    var $popover = $('.bg-booking-popover').find('div:first')
    var $a1 = $popover.find('.bg-room-1-adults')
    var $rooms = $('#bgform #bg-num-rooms')

    var $a2 = $popover.find('.bg-room-2-adults')
    var $a3 = $popover.find('.bg-room-3-adults')
    var $b1 = $popover.find('.bg-room-1-children')
    var $b2 = $popover.find('.bg-room-2-children')
    var $b3 = $popover.find('.bg-room-3-children')

    var totalguests = 0
    let adults = []
    let children = []

    // Estrae il numero di adulti
    var pattern = /[0-9]{1,2}/i
    var b1 = 0
    var b2 = 0
    var b3 = 0
    var a1 = parseInt(pattern.exec($a1.bgselect().get_value())[0], 10)
    if ($a2.attr('class')) {
      var a2 = parseInt(pattern.exec($a2.bgselect().get_value())[0], 10)
    }

    if ($a3.attr('class')) {
      var a3 = parseInt(pattern.exec($a3.bgselect().get_value())[0], 10)
    }

    if ($b1.attr('class')) {
      b1 = parseInt(pattern.exec($b1.bgselect().get_value())[0], 10)
    }

    if ($b2.attr('class')) {
      b2 = parseInt(pattern.exec($b2.bgselect().get_value())[0], 10)
    }
    if ($b3.attr('class')) {
      b3 = parseInt(pattern.exec($b3.bgselect().get_value())[0], 10)
    }
    let num_rooms = parseInt($rooms.bgselect().get_value() || 1, 10)

    switch (num_rooms) {
      case 1:
        $('.adults span').text(a1)
        $('.children span').text(b1)
        totalguests = a1 + b1
        children.push(b1)
        adults.push(a1)
        break
      case 2:
        $('.adults span').text(a1 + a2)
        $('.children span').text(b1 + b2)
        totalguests = a1 + a2 + b1 + b2
        children.push(b1)
        children.push(b2)
        adults.push(a1)
        adults.push(a2)
        break
      case 3:
        $('.adults span').text(a1 + a2 + a3)
        $('.children span').text(b1 + b2 + b3)
        totalguests = a1 + a2 + a3 + b1 + b2 + b3
        children.push(b1)
        children.push(b2)
        children.push(b3)
        adults.push(a1)
        adults.push(a2)
        adults.push(a3)
        break
      default:
        totalguests = 2
        adults.push(2)
        children.push(0)
        break
    }

    // Seleziona solo la parte numerica dell'etÃ
    // dei bambini
    const age_regex = /(0(-)?1)|(\d+){1,3}/

    for (let i = 0; i < num_rooms; i++) {
      let ages = []
      if (children) {
        for (let j = 0; j < children[i]; j++) {
          let age = $('#webuiPopover0')
            .find('.bg-room-' + (i + 1) + '-child-' + (j + 1))
            .bgselect()
            .get_value()
          const matches = age_regex.exec(age)

          if (matches) {
            age = matches[0]
          }

          if (age === '0-1') {
            age = '0'
          }

          ages.push(age)
        }
      }
      this.reservation.rooms_occupation[i] = {
        adults: adults[i],
        children: children[i],
        children_ages: ages.join(',')
      }
    }

    this.reservation.guests = totalguests
    this.reservation.adults = adults.reduce(function(a, b) {
      return a + b
    }, 0)
    this.reservation.children = children.reduce(function(a, b) {
      return a + b
    }, 0)
    this.reservation.rooms = num_rooms
  }
  submitForm(e) {
    // Compone l'url ai cui punta l'iframe
    const url = $('#form-prebooking')
      .attr('action')
      .concat('&')
      .concat(this.booking_embedded ? 'embed=1' : 'embed=0')
      .concat('&')
      .concat(this.reservation.serialize())

    if (this.booking_embedded) {
      const $loader = new Modal('#bookingenius-modal-loader')
      $loader.show()

      const $modal = $('#bookingenius-booking-modal')
      const booking_modal = new Modal('#bookingenius-booking-modal', {
        draggable: false
      })
      booking_modal.addTitleBar()

      // Controlla se ga è presente e se il form del booking
      // è decorato, utilizza il codice di tracking
      GoogleAnalytics.readyOrNot((ready, ga) => {
        let ga_query_string = ''

        try {
          ga_query_string = ready
            ? '&_ga=' + ga.extract('#bgform input[name=_ga]')
            : ''
        } catch (error) {
          console.log(error)
        }

        $modal
          .find('iframe')
          .attr('src', url + ga_query_string)
          .on('load', () => {
            $loader.hide()
            booking_modal.show()
            $modal.find('.bg-booking-modal-dismiss').addClass('visible')
          })
      })
    } else {
      GoogleAnalytics.readyOrNot((ready, ga) => {
        const ga_query_string = ready
          ? '&_ga=' + ga.extract('#bgform input[name=_ga]')
          : ''
        window.open(url + ga_query_string)
      })
    }
  }
  setUrl() {
    // Inizializza il form
    // impostando l'url da chiamare e altri attributi utili
    this.form.attr('method', 'get')
    this.form.attr('target', '_blank')
    this.form.attr(
      'action',
      'https://secure.begenius.it/bookingenius/hotel_web/' +
        this.locale +
        '/' +
        this.channel +
        '/?v=1'
    )
  }
}

module.exports = FormDom
