const Skin = require( './Skin.js' );

class BookingEmbeddedSkin extends Skin {
    constructor() {      
        super();
        // La skin di default
        this.base_skin = {
            booking_embedded_border_color: "rgba(12,12,12,0.4)",
            booking_embedded_text_color: "#FFFFFF",
            booking_embedded_background_color: "rgba(200,200,200,1)",
            booking_embedded_primary_color: "#79A503", 
            booking_embedded_close_icon_background_color: "rgba(200,0,0,0.6)",
        };      
    }
    
    compile( request_skin ) {
      let skin = Object.assign( this.base_skin, request_skin );
                
        const css = `    
          #bookingenius-booking-modal iframe {
            border: 1px solid ${ skin.booking_embedded_border_color };           
          }

          #bookingenius-booking-modal .bg-booking-modal-content .bg-booking-modal-title-bar {          
                       
          }                    

          #bookingenius-booking-modal .bg-booking-modal-content .bg-booking-modal-title-bar .bg-booking-modal-dismiss {
              border-color: ${ skin.text_color };
              background-color: ${ skin.button_background_color };
          }

          .sk-folding-cube .sk-cube:before {
            background-color: ${ skin.booking_embedded_text_color };
          }
       `
       this.css = css;
       return css;
  }
}
 
module.exports = BookingEmbeddedSkin;