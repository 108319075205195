const moment = global.$G.moment;
const config = global.$G.bgwidget.config;

let checkin_date;
let checkout_date;
let num_nights;

class Reservation {
  constructor() {
    checkin_date = moment(new Date()).format("YYYY-MM-DD");
    checkout_date = moment(checkin_date)
      .add(1, "DAY")
      .format("YYYY-MM-DD");
    this.rooms = 1;
    this.rooms_occupation = [];
    this.rooms_occupation.push({
      adults: 2,
      children: 0,
      children_ages: ""
    });
    this.code = '';
    this.rid = '';
    this.guests = 0;
    this.adults = 0;
    this.children = 0;
  }

  get checkin() {
    return checkin_date;
  }
  set checkin(value) {
    checkin_date = moment(value, "YYYY-MM-DD").format("YYYY-MM-DD");
    checkout_date = moment(value, "YYYY-MM-DD")
      .add(1, "DAY")
      .format("YYYY-MM-DD");
  }

  get checkout() {
    return checkout_date;
  }
  set checkout(value) {
    checkout_date = moment(value, "YYYY-MM-DD").format("YYYY-MM-DD");
  }

  set num_nights(value) {
    num_nights = value;
    checkout_date = moment(checkin_date, "YYYY-MM-DD")
      .add(value, "DAY")
      .format("YYYY-MM-DD");
  }

  serialize() {
    let rooms_occupation = "";
    for (let i = 0; i < this.rooms; i++) {
      let occupation = this.rooms_occupation[i];
      rooms_occupation += occupation.adults;
      if (occupation.children > 0) {
        rooms_occupation += "," + occupation.children_ages;
      }
      rooms_occupation += "-";
    }
    rooms_occupation = rooms_occupation.substring(
      0,
      rooms_occupation.length - 1
    );

    if (config.compact) {
      this.num_nights = num_nights;
    }

    if (config.rid != '') {
      this.rid = config.rid;
    }

    return encodeURI(
      `checkin=${checkin_date}&checkout=${checkout_date}` +
        `&rooms=${rooms_occupation}&code=${this.code}&rid=${this.rid}`
    );
  }
}

module.exports = Reservation;
