// La configurazione di default del widget
const config = {
  max_rooms: 3,
  max_adults: 10,
  max_children: 4,
  compact: false,
  min_nights: 3,
  max_nights: 15,
  default_room_guests: 1,
  default_checkin: 0,
  booking_embedded: false,
  dismiss_icon_mobile_position: 'bottom-left',
  orientation: 'horizontal',
  min_children_age: 0,
  max_children_age: 10,
  auto_fill_dates: false,
  error_color: 'crimson',
  font_family: 'Arial',
  fallback_font_family: 'sans-serif',
  label_font_size: '16px',
  font_size: '14px',
  label_color: 'red',
  icons_color: 'blue',
  text_color: 'white',
  controls_padding_left: '10px',
  controls_color: 'green',
  controls_border_width: '1px',
  controls_background_color: 'purple',
  controls_border_color: '#DEDEDE',
  button_text_color: 'white',
  button_background_color: 'maroon',

  datepicker_font_family: 'Palanquin',
  datepicker_fallback_font_family: 'sans-serif',
  datepicker_primary_color: '#8BC34A',
  datepicker_light_primary_color: 'orange',
  datepicker_dark_primary_color: '#689F38',
  datepicker_text_color: 'rgba(0,0,0,0.78)',

  datepicker_header_text_color: '#FFFFFF',
  datepicker_disabled_days_color: '#000000',
  datepicker_selected_day_color: 'orange',
  datepicker_body_background_color: '#FFFFFF',
  datepicker_date_format: 'DD/MM/YYYY',

  guests_primary_color: '#8BC34A',
  guests_light_primary_color: 'orange',
  guests_dark_primary_color: '#689F38',
  guests_text_color: 'rgba(0,0,0,0.78)',
  guests_tabs_text_color: 'red',
  guests_font_family: 'PT Sans',
  guests_fallback_font_family: 'sans-serif',
  guests_tabs_font_size: '12px',
  guests_controls_font_size: '14px',
  guests_label_font_size: '12px',
  guests_tabs_border_width: '1px',

  rid: ''
}
module.exports = config
