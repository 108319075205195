class Skin {	
	constructor() {
		this.skin = "";
		this.id;
		this.is_live = false;
		this.allowed_font_fallbacks = [
          "sans-serif",
          "serif",
          "inherit"
        ];
	}
	is_allowed_fallback( fallback ) {
		let found = this.allowed_font_fallbacks.indexOf( fallback ); 
        return found < 0 ? false : true;
	}
	publish() {
		const current_skin = document.getElementById( this.id );

		if ( current_skin ) {
			current_skin.remove();
		}

		const new_skin = document.createElement( "style" );
		
		new_skin.setAttribute( "id", this.id );
    	new_skin.setAttribute( "type", "text/css" );
    	new_skin.innerHTML = this.css;

    	document.getElementsByTagName( "head" )[ 0 ].appendChild( new_skin );
	}
}

module.exports = Skin;