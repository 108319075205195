function get_date() {
  const d = new Date();
  const month = d.getUTCMonth() < 10 ? "0" + d.getUTCMonth() : d.getUTCMonth();
  return (
    d.getUTCFullYear() +
    "-" +
    month +
    "-" +
    d.getUTCDate() +
    " " +
    d.getUTCHours() +
    ":" +
    d.getUTCMinutes() +
    ":" +
    d.getUTCSeconds() +
    "." +
    d.getUTCMilliseconds()
  );
}
const mode = "";
class Logger {
  constructor(mode = "production") {
    this.channel = "";
    mode = mode;
  }
  info(message) {
    if (mode === "debug") {
      const d = get_date();
      console.info(d + " [" + this.channel + "]" + ": " + message);
    }
  }
  warn(message) {
    const d = get_date();
    console.warn(d + " [" + this.channel + "]" + ": " + message);
  }
  error(message) {
    const d = get_date();
    console.error(d + " [" + this.channel + "]" + ": " + message);
  }
}

module.exports = Logger;
