module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="bgform">\r\n  <form id="form-prebooking" name="bookingform" class="bg-booking-form-';
 if ( is_vertical ) { 
__p+='vertical';
 } else { 
__p+='horizontal';
 } 
__p+='">\r\n    <div class="bg-booking-form-inline bg-booking-row">\r\n        <div class="bg-booking-form-group field1 ';
 if ( is_vertical === false && max_rooms === 1 ) { 
__p+='  bg-booking-col-desktop-push-1half-24 bg-booking-col-tablet-push-1half-24 ';
 } 
__p+='';
 if ( is_vertical ) { 
__p+=' bg-booking-col-phone-24-24 ';
 } else { 
__p+=' bg-booking-col-phone-24-24 bg-booking-col-tablet-4-24 bg-booking-col-desktop-4-24';
 } 
__p+='">\r\n            <label>\r\n                '+
((__t=( checkin ))==null?'':__t)+
'\r\n            </label>\r\n            <div class="bg-booking-input-group">\r\n                <input name="checkin" type="text" value="'+
((__t=( checkin_date ))==null?'':__t)+
'" placeholder="'+
((__t=( polyglot.t("checkin_error_text") ))==null?'':__t)+
'" readonly="readonly" />\r\n                <div class="bg-booking-append">\r\n                  <div class="--clickable fa fa-calendar-o"></div>\r\n                  <div class="--clickable fa fa-exclamation-circle"></div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n        <div class="bg-booking-form-group field2 ';
 if ( is_vertical ) { 
__p+=' bg-booking-col-phone-24-24 ';
 } else { 
__p+=' bg-booking-col-phone-24-24 bg-booking-col-tablet-4-24 bg-booking-col-desktop-4-24';
 } 
__p+='">\r\n            ';
 if ( !compact ) { 
__p+='\r\n            <label for="departure">\r\n                 '+
((__t=( checkout ))==null?'':__t)+
'\r\n            </label>\r\n            <div class="bg-booking-input-group">\r\n                <input  name="checkout" type="text" value="'+
((__t=( checkout_date ))==null?'':__t)+
'"} placeholder="'+
((__t=( polyglot.t("checkout_error_text") ))==null?'':__t)+
'" readonly="readonly" />\r\n                <div class="bg-booking-append">\r\n                  <div class="--clickable fa fa-calendar-o"></div>\r\n                  <div class="--clickable fa fa-exclamation-circle"></div>\r\n                </div>\r\n            </div>\r\n            ';
 } else { 
__p+='\r\n                <label for="bg-num-nights">\r\n                    '+
((__t=( nights ))==null?'':__t)+
'\r\n                </label>\r\n                <div class="bg-booking-select" name="bg-num-nights" data-role="select">\r\n                    <ul class="bg-booking-menu">\r\n                        <li data-role="default">'+
((__t=( min_nights ))==null?'':__t)+
'</li>\r\n                        ';
 for( var i=min_nights + 1; i <= max_nights; i++) { 
__p+='\r\n                          <li>'+
((__t=( i ))==null?'':__t)+
'</li>\r\n                      ';
 } 
__p+='\r\n                  </ul>\r\n                </div>\r\n            ';
 } 
__p+='\r\n        </div>\r\n        ';
 if ( max_rooms > 1 ) { 
__p+='\r\n        <div class="bg-booking-form-group field3 ';
 if ( is_vertical ) { 
__p+=' bg-booking-col-phone-24-24 ';
 } else { 
__p+=' bg-booking-col-phone-24-24 bg-booking-col-tablet-2-24 bg-booking-col-desktop-3-24';
 } 
__p+='">\r\n          <label for="bg-num-rooms">\r\n              '+
((__t=( polyglot.t("rooms", 2 ) ))==null?'':__t)+
'\r\n          </label>\r\n              <div class="bg-booking-select" name="bg-num-rooms" id="bg-num-rooms" data-role="select">\r\n                  <ul class="bg-booking-menu">\r\n                      <li data-role="default">1</li>\r\n                      ';
 for( var i=2; i <= max_rooms; i++) { 
__p+='\r\n                          <li>'+
((__t=( i ))==null?'':__t)+
'</li>\r\n                      ';
 } 
__p+='\r\n                  </ul>\r\n\r\n          </div>\r\n        </div>\r\n        ';
 } 
__p+='\r\n        <div class="bg-booking-form-group field4 ';
 if ( is_vertical ) { 
__p+=' bg-booking-col-phone-24-24 ';
 } else { 
__p+=' bg-booking-col-phone-24-24 bg-booking-col-tablet-5-24 bg-booking-col-desktop-4-24';
 } 
__p+='">\r\n            <label for="bg-room-guests">\r\n            '+
((__t=( guests ))==null?'':__t)+
'\r\n            </label>\r\n            <div id="bg-room-guests" class="guests bg-room-guests">\r\n                <div class="guests-container">\r\n                  <div>\r\n                    <div class="adults">'+
((__t=( polyglot.t("adults", 2) ))==null?'':__t)+
': <span id="bg-num-adults">'+
((__t=( default_room_guests ))==null?'':__t)+
'</span></div>\r\n                    ';
 if ( max_children > 0 ) { 
__p+='\r\n                        <div class="children">'+
((__t=( polyglot.t("children", 2) ))==null?'':__t)+
': <span id="bg-num-children">0</span></div>\r\n                    ';
 } 
__p+='\r\n                  </div>\r\n                </div>\r\n            </div>\r\n            '+
((__t=( popover ))==null?'':__t)+
'\r\n            <input type="hidden" id="room-guests"  name="room-guests"  type="text" value="'+
((__t=( default_room_guests ))==null?'':__t)+
'" />\r\n        </div>\r\n        <div class="bg-booking-form-group field5 ';
 if ( is_vertical ) { 
__p+=' bg-booking-col-phone-24-24 ';
 } else { 
__p+=' bg-booking-col-phone-24-24 bg-booking-col-tablet-4-24 bg-booking-col-desktop-4-24';
 } 
__p+=' position-relative promo-code">\r\n            <label class="anchor" for="code">'+
((__t=( code ))==null?'':__t)+
'</label>\r\n            <input type="text" name="code" placeholder="'+
((__t=( polyglot.t('promo_code') ))==null?'':__t)+
'" value=""/>\r\n        </div>\r\n\r\n        <div class="bg-booking-form-group field6 ';
 if ( is_vertical ) { 
__p+=' bg-booking-col-phone-24-24 ';
 } else { 
__p+=' bg-booking-col-phone-24-24 bg-booking-col-tablet-5-24 bg-booking-col-desktop-5-24';
 } 
__p+='">\r\n            <button class="bg-booking-submit" type="button" name="submit-booking">\r\n              '+
((__t=( button_text ))==null?'':__t)+
'\r\n            </button>\r\n        </div>\r\n      </div>\r\n  </form>\r\n</div>\r\n';
}
return __p;
};
