const Skin = require( './Skin.js' );

class FormSkin extends Skin {
    constructor(  ) {
        super();
    }

    compile( request_skin ) {

        let skin = Object.assign( global.$G.bgwidget.config, request_skin );

        let import_font = '';
        if ( skin.font_url && skin.font_url.length > 0 ) {
            import_font = `@import url(${ skin.font_url });`;
        }

        if ( this.is_allowed_fallback( skin.fallback_font_family ) === false ) {
            skin.fallback_font_family = '"' + skin.fallback_font_family + '"';
        }

        if ( this.is_allowed_fallback( skin.font_family ) === false ) {
            skin.font_family = '"' + skin.font_family + '"';
        }


        const css = `
                ${import_font}

                #bgform form label,
                #bg-form-mobile form label,
                #bg-booking-popover form label,
                #bgform form button {
                   font-family: ${ skin.font_family}, ${ skin.fallback_font_family }!important;
                   color: ${ skin.label_color };
                 }

                #bgform form label.anchor:after {
                    border-color: ${ skin.label_color };
                }
                 #bgform form button,
                 #bgform form input,
                 #bgform form select,
                 #bgform form .bg-booking-select,
                 #bgform form #bg-room-guests {
                   font-family: ${ skin.font_family }, ${ skin.fallback_font_family }!important;
                   color: ${ skin.text_color };
                   background-color: ${ skin.controls_background_color };
                   border: ${ skin.controls_border_width } solid ${ skin.controls_border_color}!important;
                   padding-left: ${ skin.controls_padding_left };
                   font-size: ${ skin.font_size };
                 }

                #bgform form button {
                    padding: 0;
                }
                #bgform form #bg-room-guests {
                    padding-left: 4px;
                }

                .bg-booking-menu ul {
                    font-size: ${ skin.font_size };
                }

                 #bgform form label {
                    font-size: ${ skin.label_font_size };
                 }


                 #bgform form button {
                   color: ${ skin.button_text_color }!important;
                   background-color: ${ skin.button_background_color }!important;
                 }

                 #bgform form .bg-booking-append,
                 #bgform form .bg-booking-select:after{
                   color: ${ skin.icons_color }!important;
                 }

                 #bgform form .bg-booking-append .fa-exclamation-circle {
                   color: ${ skin.error_color }!important;
                 }

                 #bgform form .bg-booking-input-group.error input {
                   color: ${ skin.error_color }!important;
                   outline-color: ${ skin.error_color }!important;
                 }

                #bgform form .bg-booking-input-group.error input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                  color: ${ skin.error_color }!important;
                }
                #bgform form .bg-booking-input-group.error input::-moz-placeholder { /* Firefox 19+ */
                  color: ${ skin.error_color }!important;
                }
                #bgform form .bg-booking-input-group.error input:-ms-input-placeholder { /* IE 10+ */
                  color: ${ skin.error_color }!important;
                }
                #bgform form .bg-booking-input-group.error input:-moz-placeholder { /* Firefox 18- */
                  color: ${ skin.error_color }!important;
                }



                 #bgform .guests-container {
                    margin: 0;
                 }

                 #bgform .bg-booking-select {
                     border: 0;
                     height: 42px;
                     line-height: 42px;
                     font-size: inherit;
                     font-family: inherit;
                     background-color: #FFFFFF;
                     font-family: ${ skin.font_family }, ${ skin.fallback_font_family }!important;
                 }


                 #bg-form-mobile {
                   background-color: #FFFFFF;
                   font-family: ${ skin.font_family }, ${ skin.fallback_font_family }!important;
                 }


                 #bg-form-mobile a {
                   color: ${ skin.mobile_form_close_icon_color }!important;
                   text-decoration: none;
                   font-style: normal;
                   font-family: ${ skin.font_family }, ${ skin.fallback_font_family }!important;
                   font-weight: bold;
                   font-size: 20px;
                 }

                 #bg-booking-icon {
                   background-color: ${ skin.mobile_button_background_color }!important;
                   color: ${ skin.mobile_button_icon_color };
                 }


                 #bgform button {
                     color: ${ skin.button_text_color };
                     background-color: ${ skin.button_background_color };
                 }


                 .bg-booking-select:after {
                   color: ${ skin.icons_color }!important;
                 }

                 .bg-booking-select,
                 .bg-booking-menu {
                    color: ${ skin.controls_text_color };
                    font-family: ${ skin.font_family }, ${ skin.fallback_font_family }!important;
                 }

                ul.bg-booking-menu li {
                     padding: 0 ${ skin.controls_padding_left }!important;
                }

                .bg-booking-tabs ul.bg-booking-nav li {
                     border-color: ${ skin.tabs_border_color }!important;
                }

                .webui-popover {
                    border-radius: 0!important;
                }

        `;

        this.css = css;
        return css;
    }
};

module.exports = FormSkin;
