const $ = global.$G.jquery;

class TitleBar {	
	constructor( modal ) {
		modal.dismiss.on( "click", e => {
			modal.flip();
			setTimeout(() => {
				modal.hide();
			}, 300);
		});
	}	
}
module.exports = TitleBar;