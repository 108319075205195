const Skin = require( './Skin.js' );
class GuestsSelectorSkin extends Skin {
  constructor() {
    super();

  }
  compile( request_skin ) {
    
  let skin = Object.assign( global.$G.bgwidget.config, request_skin );
    
  if ( this.is_allowed_fallback( skin.guests_fallback_font_family ) === false ) {
    skin.guests_fallback_font_family = '"' + skin.guests_fallback_font_family + '"';
  }
  const css = `
    .webui-popover {
          border-radius: 0!important;
          webkit-box-shadow: 0px 5px 6px 0px rgba(100, 100, 100, 0.38) !important;
          -moz-box-shadow: 0px 5px 6px 0px rgba(100, 100, 100, 0.38) !important;
          box-shadow: 0px 5px 6px 0px rgba(100, 100, 100, 0.38) !important;
        }
            
        .webui-popover ul li a {
          color: ${ skin.guests_tabs_text_color }!important;
          font-size: ${ skin.guests_tabs_font_size };
        }
        .webui-popover .bg-booking-tabs ul.bg-booking-nav li {
            border-color: ${ skin.guests_dark_primary_color }!important;
            border-width: ${ skin.guests_tabs_border_width};
        } 
        
        .webui-popover label {
            color: ${ skin.guests_dark_primary_color }!important;
        }
            
        .webui-popover .bg-booking-select:after {
            color: ${ skin.guests_primary_color }!important;
        }
                      
        .webui-popover label,
        .webui-popover ul.bg-booking-nav a,
        ul.bg-booking-menu-popover,
        .webui-popover .bg-booking-select {
          font-family: ${ skin.guests_font_family }, ${ skin.guests_fallback_font_family }!important;               
        }
      
        .webui-popover .bg-booking-select  {
          font-size: ${ skin.guests_controls_font_size };
        }

        .webui-popover label  {
          font-size: ${ skin.guests_label_font_size }!important;
        }

  `;
  this.css = css;
  return css;
  }
}

module.exports = GuestsSelectorSkin;