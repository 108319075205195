module.exports={
	"checkin": "Checkin",
	"checkout": "Checkout",
	"rooms": "Room||||Rooms",
	"guests": "Guests",
	"code": "Do you have a promotional code?",
	"promo_code": "Promotional code",
	"button_text": "Check availability",
	"adults": "Adult||||Adults",
	"children": "Child||||Children",
	"nights": "Num. nights",
	"children_ages": "Children ages (year)",
	"age": "Age",
	"year": "year||||years",
	"loader_text": "We are looking for the <span class='genius'>best solution...</span>",
	"checkin_error_text": "Choose the date of your arrival",
	"checkout_error_text": "Choose the date of your departure"
}
