module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=' <div id="bg-booking-popover" class="webui-popover-content ';
 if( max_children === 0 ) { 
__p+=' webui-popover-small ';
 } 
__p+='">\r\n    <nav class="bg-booking-tabs" data-role="tabs">\r\n        <ul id="bg-rooms-nav" class="bg-booking-nav"><li class="active"><a href="[data-tab=bg-room-1]">'+
((__t=( polyglot.t("rooms", 1) ))==null?'':__t)+
' 1</a></li><li class="bg-booking-hidden"><a href="[data-tab=bg-room-2]">'+
((__t=( polyglot.t("rooms", 1) ))==null?'':__t)+
' 2</a></li><li class="bg-booking-hidden"><a href="[data-tab=bg-room-3]">'+
((__t=( polyglot.t("rooms", 1) ))==null?'':__t)+
' 3</a></li></ul>\r\n        <div class="bg-booking-tab-content">\r\n            ';
 for (var i=1; i <= max_rooms ; i++) { 
__p+='\r\n            <div data-tab="bg-room-'+
((__t=( i ))==null?'':__t)+
'" class="tab ';
 if ( i === 1) { 
__p+='active';
 } 
__p+='" >    \r\n                <label>'+
((__t=( polyglot.t("adults", 2) ))==null?'':__t)+
'</label>\r\n                <div class="bg-booking-select bg-room-'+
((__t=( i ))==null?'':__t)+
'-adults" data-role="select">                                    \r\n                    <ul class="bg-booking-menu bg-booking-menu-popover"> \r\n                        ';
 for (var j=1; j <= max_adults; j++) { 
__p+='                                            \r\n                          <li ';
 if( j === default_room_guests ) { 
__p+='data-role="default"';
 } 
__p+=' >'+
((__t=( j ))==null?'':__t)+
' '+
((__t=( polyglot.t("adults", j).toLowerCase() ))==null?'':__t)+
'</li>\r\n                        ';
 } 
__p+='\r\n                    </ul>\r\n                </div>\r\n                ';
 if ( max_children > 0 ) { 
__p+='\r\n                <label>'+
((__t=( polyglot.t("children", 2) ))==null?'':__t)+
'</label>\r\n                <div data-tab="bg-room-'+
((__t=( i ))==null?'':__t)+
'-children" class="bg-booking-select bg-room-children bg-room-'+
((__t=( i ))==null?'':__t)+
'-children" data-role="select">\r\n                    <ul class="bg-booking-menu bg-booking-menu-popover">  \r\n                        <li data-role="default">0 '+
((__t=( polyglot.t("children", 0).toLowerCase() ))==null?'':__t)+
'</li>\r\n                        ';
 for (var j=1; j <= max_children; j++) { 
__p+='                      \r\n                          <li>'+
((__t=( j ))==null?'':__t)+
' '+
((__t=( polyglot.t("children", j).toLowerCase() ))==null?'':__t)+
'</li>\r\n                        ';
 } 
__p+='\r\n                    </ul>\r\n                </div>                                    \r\n                <div data-tab="bg-room-'+
((__t=( i ))==null?'':__t)+
'-children-setup" class="bg-booking-hidden">                       \r\n                  <label>'+
((__t=( polyglot.t("children_ages") ))==null?'':__t)+
'</label>\r\n                    ';
 for (var j=0; j < max_children; j++) { 
__p+='  \r\n                    <div class="bg-booking-col-no-stack-6-12 bg-booking-col-phone-12-24" ';
 if( j > 1 ) { 
__p+=' style="margin-top:10px;" ';
 } 
__p+=' >\r\n                        <div class="bg-booking-select bg-booking-hidden bg-room-'+
((__t=( i ))==null?'':__t)+
'-child-'+
((__t=( ( j + 1 ) ))==null?'':__t)+
'" data-role="select">                                            \r\n                            <ul class="bg-booking-menu bg-booking-menu-popover">\r\n                                <li data-role="default" class="bg-booking-hidden">'+
((__t=( polyglot.t("age") ))==null?'':__t)+
'</li>\r\n                                ';
 for (var a = min_children_age; a <= max_children_age; a++) { 
__p+='\r\n                                    ';
 if ( a === 0) { 
__p+='\r\n                                        <li>0-1 '+
((__t=( polyglot.t("year", 0) ))==null?'':__t)+
'</li>                               \r\n                                    ';
 } 
__p+='\r\n                                  <li>'+
((__t=( a ))==null?'':__t)+
' '+
((__t=( polyglot.t("year", a) ))==null?'':__t)+
'</li>\r\n                                ';
 } 
__p+='\r\n                            </ul>\r\n                        </div>\r\n                    </div>\r\n                    ';
 } 
__p+='\r\n                </div>\r\n                ';
 } 
__p+='\r\n            </div>                                \r\n            ';
 } 
__p+='                                                \r\n        </div>\r\n    </nav>\r\n</div>';
}
return __p;
};
