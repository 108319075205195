require( "babel-polyfill" );
require( "custom-event-polyfill" );
const FormSkin            = require( './modules/FormSkin.js' );
const DatepickerSkin      = require( './modules/DatepickerSkin.js' );
const GuestsSelectorSkin  = require( './modules/GuestsSelectorSkin.js' );
const BookingEmbeddedSkin = require( './modules/BookingEmbeddedSkin.js' );
const ResponsiveSkin      = require( './modules/ResponsiveSkin.js' );
const default_config      = require( './modules/Config.js' );

// Manifest per lookup dipendenze
const revs = require( "./rev-manifest.json" );

const Logger = require( "./modules/Logger.js" );

global.$G = global.$G || {};
global.$G.bgwidget = global.$G.bgwidget || {};
global.$G.bgwidget.manifest = revs;

const g = global.$G;

// Logger
const logger = new Logger( "debug" );
logger.channel = "bookingenius-widget";

// Dipendenze css e js
const css_dependencies = [
  revs[ "css/bookingenius-widget.css" ],
];

// In vendor1.js vengono caricate le traduzioni del datepicker
const js_dependencies = [
  revs[ "vendor1.js" ],
  revs[ "vendor2.js" ],
  revs[ "vendor3.js" ]
];

// Ricaviamo l'url presso cui scaricare tutte le risorse
const script = document.getElementById( "bookingenius-widget-script" );
const res_url = script.getAttribute( "src" ).replace( "app.bookingenius-widget.js", "" );

// Pubblico l'url in modo da poterlo utilizzare negli altri moduli
g.bgwidget.resources_url = res_url;

// La configurazione del widget
const requested_config = JSON.parse(script.getAttribute("data-configuration"));

const { min_nights, max_nights } = requested_config;
if (min_nights > max_nights) {
  requested_config.max_nights = requested_config.min_nights;
  console.log(
    `⚠️ min_nights=${min_nights} is greater than max_nights=${max_nights}`
  );
  console.log(`⚠️ max_nights has been set to ${min_nights}`);
}

if (typeof requested_config.datepicker_selected_day_color === "undefined") {  
  requested_config.datepicker_selected_day_color = requested_config.datepicker_primary_color;
}

// Effettua il merge della configurazione con quella di default
let merged_config = Object.assign( default_config, requested_config );
// Pubblico la configurazione del widget
global.$G.bgwidget.config = merged_config;
console.dir(global.$G.bgwidget.config);
const f = document.createDocumentFragment();

js_dependencies.forEach( url => {

  let script_tag = document.createElement( "script" );
  script_tag.setAttribute( "type", "text/javascript" );
  script_tag.setAttribute( "src", res_url + url );
  script_tag.setAttribute( "defer", "true" );
  script_tag.onload = function () {
    logger.info( url );
  }
  f.appendChild( script_tag );

} );

const body = document.getElementsByTagName( "body" )[ 0 ];
const css_tag = document.createElement( "link" );
css_tag.setAttribute( "rel", "stylesheet" );
css_tag.setAttribute( "href", res_url + css_dependencies[ 0 ] );
css_tag.onload = () => {
  const e = new CustomEvent( "stylesheet" );
  body.dispatchEvent( e );
}

f.appendChild( css_tag );

( document.getElementsByTagName( "head" )[ 0 ] || document.documentElement )
.appendChild( f );

const messages = {
  it: require( "./messages/it.json" ),
  en: require( "./messages/en.json" ),
  de: require( "./messages/de.json" ),
  fr: require( "./messages/fr.json" ),
  es: require( "./messages/es.json" ),
  pt: require( "./messages/pt.json" )
};


let cnt = 0;

function bootstrap( e ) {

  cnt++;
  if ( cnt === 4 ) {
    $G.bgwidget.messages = messages;

    global.$G.logger = logger;

    const App = require( "./modules/App.js" );
    const app = new App;
    logger.info( "app start" );
    app.run();
    logger.info( "app running" );
  }
}

body.addEventListener( "vendor1", bootstrap );
body.addEventListener( "vendor2", bootstrap );
body.addEventListener( "vendor3", bootstrap );
body.addEventListener( "stylesheet", bootstrap );

// Compilo le skin al caricamento del foglio di stile
// principale
body.addEventListener( "stylesheet", e => {

  body.addEventListener( "ElementQueries", e => {
    // Abilito element queries per widget responsive
    global.ElementQueries.init();
  } );

  // La skin del form
  const skin1 = new FormSkin;
  skin1.id = "bookingenius-widget-form-skin";
  skin1.compile( requested_config );
  skin1.publish();


  // La skin del calendario
  // La skin del form
  const skin2 = new DatepickerSkin;
  skin2.id = "bookingenius-datepicker-skin";
  skin2.compile( requested_config );
  skin2.publish();


  // Lo stile del selettore ospiti
  // La skin del form
  const skin3 = new GuestsSelectorSkin;
  skin3.id = "bookingenius-widget-form-guests-skin";
  skin3.compile( requested_config );
  skin3.publish();

  const skin4 = new BookingEmbeddedSkin;
  skin4.id = "bookingenius-booking-embedded-skin";
  skin4.compile( requested_config );
  skin4.publish();

  /* const skin5 = new ResponsiveSkin;
  skin5.id = "bookingenius-booking-responsive-skin";
  skin5.compile( );
  skin5.publish();*/

} );
