const logger = global.$G.logger;
const options =  {
	check_every_ms: 500,
	max_tries: 3,
	max_linker_tries: 3
}
let tries = 0;
let linker_require_tries = 0;

// TODO: aggiungere gestione linker mancante
class GoogleAnalytics {
	static ready( callback ) {
		const int = setInterval( () => {
			tries = tries + 1;
			if ( global.GoogleAnalyticsObject && global[ global.GoogleAnalyticsObject ] ) {
				clearInterval( int );
				logger.info( "Google Analytics has been found" );
				this.ga =  global[ global.GoogleAnalyticsObject ];
				callback.call( this, this );
			} else if ( tries === options.max_tries ) {
				clearInterval( int );
				logger.warn( "Google Analytics has not been found after " + ( options.check_every_ms * tries ) + "ms" );
			}
		}, options.check_every_ms );

	}
	static readyOrNot( callback ) {
		callback.call( this, !!global[ global.GoogleAnalyticsObject ], this );
	}
	static decorate ( selector ) {
		const element = document.querySelector( selector );
		this.ga('linker:decorate', element);
		if ( linker_require_tries < options.max_linker_tries && this.isDecorated( selector ) === false ) {
			++linker_require_tries
			this.ga('require', 'linker');
			this.decorate( selector );
		} else if ( linker_require_tries >= options.max_linker_tries ) {
			console.error( "%cCould not require Google Analytics Linker!", "font-size:20px;background-color:red; color: white;" );
			console.error( "%cCross-domain tracking won't work!", "font-size:18px;background-color:red; color: white;" );
		}
	}
	static isDecorated( selector ) {
		const gaInput = document.querySelector( selector + " input[name=_ga]" );
		return gaInput ? true : false;
	}
	static extract( selector ) {
		const element =  document.querySelector( selector );
		return element ? element.value : undefined;
	}
}

GoogleAnalytics.options = options;

module.exports = GoogleAnalytics;
