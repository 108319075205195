module.exports={
	"checkin": "llegada",
	"checkout": "Salida",
	"rooms": "Habitación||||Habitaciones",
	"guests": "Invitados",
	"code": "¿Tiene un código promocional?",
	"promo_code": "Código promocional",
	"button_text": "Revisar disponibilidad",
	"adults": "Adulto||||Adultos",
	"children": "Niño||||Niños",
	"nights": "Num. Noches",
	"children_ages": "Edades de los niños (años)",
	"age": "Edad",
	"year": "año||||años",
	"loader_text": "Buscamos la <span class='genius'>mejor solución...</span>",
	"checkin_error_text": "Elige la fecha de tu llegada",
	"checkout_error_text": "Elige la fecha de tu partida"
}
