module.exports={
	"checkin": "Einchecken",
	"checkout": "Überprüfen",
	"rooms": "Zimmer||||Zimmer",
	"guests": "Gäste",
	"code": "Haben Sie einen Gutschein-Code?",
	"promo_code": "Gutschein-Code",
	"button_text": "Verfügbarkeit prüfen",
	"adults": "Erwachsene||||Erwachsene",
	"children": "Kind||||Kinder",
	"nights": "Nächte",
	"children_ages": "Kinder im Alter von (Jahre)",
	"age": "Alter",
	"year": "Jahr||||Jahre",
	"loader_text": "Wir suchen die <span class='genius'>beste Lösung...</span>",
	"checkin_error_text": "Wählen Sie das Datum Ihrer Ankunft",
	"checkout_error_text": "Wählen Sie das Datum Ihrer Abreise"
}
