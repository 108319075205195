module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="bookingenius-modal-loader" class="bg-booking-modal-container">\r\n\t<div class="bg-booking-modal-content">\r\n\t\t<div class="bg-booking-modal-loader">\r\n\t\t\t<div class="sk-folding-cube">\r\n\t        \t<div class="sk-cube1 sk-cube"></div>\r\n\t        \t<div class="sk-cube2 sk-cube"></div>\r\n\t        \t<div class="sk-cube4 sk-cube"></div>\r\n\t        \t<div class="sk-cube3 sk-cube"></div>\r\n\t      \t</div>\r\n\t  \t \t<h3>'+
((__t=( polyglot.t( "loader_text") ))==null?'':__t)+
'</h3>\r\n\t\t</div>\r\n\t</div>\r\n</div>\r\n<div id="bookingenius-booking-modal" class="bg-booking-modal-container">\r\n\t<a href="#" class="bg-booking-modal-dismiss-mobile '+
((__t=( dismiss_icon_position ))==null?'':__t)+
'"><i class="fa fa-times"></i></a>\r\n\t<div class="bg-booking-modal-content">\r\n\t\t\t<iframe></iframe>\r\n\t\t\t<div class="bg-booking-modal-title-bar">\r\n\t\t\t\t<a href="#" class="bg-booking-modal-dismiss"><i class="fa fa-times"></i></a>\t\t\t\t\r\n\t\t\t</div>\r\n\t</div>\r\n</div>\r\n';
}
return __p;
};
