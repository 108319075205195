const $ = global.$G.jquery;
const logger = global.$G.logger;
const res_url = global.$G.bgwidget.resources_url;
const FormSkin = require('./FormSkin.js');
const DatepickerSkin = require('./DatepickerSkin.js');
const GuestsSelectorSkin = require('./GuestsSelectorSkin.js');
const validate = require('./ConfigurationValidator.js');
const DateFormatsLookup = require('./DateFormatsLookup');
import { defaultCheckin } from './helpers';

class LivePreview {
  constructor() {
    this.skins = {
      form: FormSkin,
      datepicker: DatepickerSkin,
      guests: GuestsSelectorSkin
    };
  }
  update(configuration) {
    console.log(configuration);
    let context = global.$G.bgwidget.context;
    const messages = global.$G.bgwidget.messages;
    const FormDom = global.$G.bgwidget.FormDom;

    context.locale = configuration.locale;

    context.polyglot.locale = context.locale;
    context.polyglot.extend(messages[configuration.locale]);

    // Coversione parametri di configurazione
    configuration.max_adults = parseInt(configuration.max_adults, 10);
    configuration.max_children = parseInt(configuration.max_children, 10);
    configuration.max_rooms = parseInt(configuration.max_rooms, 10);
    configuration.max_nights = parseInt(configuration.max_nights, 10);
    configuration.min_nights = parseInt(configuration.min_nights, 10);
    configuration.default_room_guests = parseInt(
      configuration.default_room_guests,
      10
    );
    configuration.dismiss_icon_position =
      configuration.dismiss_icon_mobile_position;

    context = Object.assign(global.$G.bgwidget.context, configuration);

    if (configuration.button_text === messages['it'].button_text) {
      context.button_text = messages[context.locale].button_text;
    } else {
      context.button_text = configuration.button_text;
    }
    validate(context);

    context.is_vertical =
      configuration.orientation === 'vertical' ? true : false;

    const popover_html = global.$G.bgwidget.templates.popover(context);
    const moment = global.$G.moment;
    const $checkin = $('#bgform input[name=checkin]');
    const $checkout = $('#bgform input[name=checkout]');
    const datepickerDateFormat =
      DateFormatsLookup[configuration.datepicker_date_format];

    $checkin.datepicker('hide');
    $checkout.datepicker('hide');

    moment.locale(configuration.locale);
    var checkin_date, checkout_date;

    if (configuration.auto_fill_dates) {
      checkin_date = new Date();

      checkin_date = defaultCheckin(
        checkin_date,
        configuration.default_checkin
      );

      checkout_date = moment(checkin_date)
        .add(configuration.min_nights, 'DAYS')
        .toDate();

      context.checkin_date = moment(checkin_date).format(
        configuration.datepicker_date_format
      );

      context.checkout_date = moment(checkout_date).format(
        configuration.datepicker_date_format
      );
    } else {
      context.checkin_date = '';
      context.checkout_date = '';
    }

    const regional = global.$G.jquery.datepicker.regional;

    // Non ricarico due volte le stesse traduzioni
    // E nemmeno la lingua inglese perchè è sempre presente
    if (context.locale !== 'en' && !regional[context.locale]) {
      const script_tag = document.createElement('script');
      script_tag.setAttribute('type', 'text/javascript');
      script_tag.setAttribute(
        'src',
        res_url + 'jquery-ui/ui/i18n/datepicker-' + context.locale + '.js'
      );
      script_tag.setAttribute('defer', 'true');
      document.getElementsByTagName('body')[0].appendChild(script_tag);
    } else {
      $.datepicker.setDefaults(regional[context.locale]);
    }
    context.popover = popover_html;

    const html = global.$G.bgwidget.templates.form(context);

    $('#bookingenius-widget').html(html);
    $checkin.datepicker('option', 'dateFormat', datepickerDateFormat);
    $checkout.datepicker('option', 'dateFormat', datepickerDateFormat);

    if (context.booking_embedded) {
      const modal = require('../assets/templates/modal.tpl');
      const modal_html = modal(context);
      const $body = $('body');

      $body.find('#bookingenius-modal-loader').remove();

      $body.find('#bookingenius-booking-modal').remove();

      $body.append(modal_html);
    }

    const form_dom = new FormDom(context);
    form_dom.init();
    form_dom.channel = configuration.channel;
    form_dom.reservation.checkin = moment(checkin_date).format('YYYY-MM-DD');
    form_dom.reservation.checkout = moment(checkout_date).format('YYYY-MM-DD');
    form_dom.setUrl();
  }
  datepickerDateToUTC({ selectedDay, selectedMonth, selectedYear }) {
    if (selectedDay.length === 1) {
      selectedDay = '0' + selectedDay;
    }
    if (selectedMonth + 1 < 10) {
      selectedMonth = '0' + (selectedMonth + 1);
    } else {
      selectedMonth = selectedMonth + 1;
    }
    // formato YYYY-MM-DD
    var UTC = `${selectedYear}-${selectedMonth}-${selectedDay}`;
    console.log(UTC);
    return UTC;
  }
}

module.exports = LivePreview;
