module.exports={
	"checkin": "Arrivo",
	"checkout": "Partenza",
	"rooms": "Camera||||Camere",
	"guests": "Ospiti",
	"code": "Hai un codice promozionale?",
	"promo_code": "Codice promozionale",
	"button_text": "Verifica disponibilità",
	"adults": "Adulto||||Adulti",
	"children": "Bambino||||Bambini",
	"nights": "Numero notti",
	"children_ages": "Età dei bambini (anni)",
	"age": "Età",
	"year": "anno||||anni",
	"loader_text": "Stiamo cercando la soluzione <span class='genius'>migliore...</span>",
	"checkin_error_text": "Inserisci una data di arrivo",
	"checkout_error_text": "Inserisci una data di partenza"
}
